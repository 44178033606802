import React from "react"

import TimelineItemComponent from "./TimelineItem"


function ItemSizingToClass(item:string):string {
  switch(item) {
    case "small":
      return "col-sm-12 col-md-2"
    case "large":
      return "col-sm-12 col-md-3"
    default:
      return "col-sm-12 col-md"
  }
}

// Status numbers: 1 - active, 2 - complete, anything else is incomplete
interface TimelineItem {
  _uid: string,
  title: string,
  short_description: string,
  status: string,
  link: SBLink,
  ispring_url: string
}
interface TimelineProps {
  items: Array<TimelineItem>,
  item_sizing: string,
  timeline_color?: string
}
function TimelineContainer({ items, item_sizing, timeline_color }:TimelineProps):JSX.Element {


  return (
    <div className="row g-0">
      {items.map((item,idx)=>(
        <div key={item._uid || idx} className={ItemSizingToClass(item_sizing)}>
          <TimelineItemComponent
            status={item.status}
            showLeftLine={idx != 0}
            showRightLine={idx < items.length-1}
            lineColor={timeline_color || ""}
            title={item.title}
            shortDescription={item.short_description}
            link={item.link} 
            ispring_url={item.ispring_url}/>
        </div>
      ))}
    </div>
  )
}

export { TimelineItem }
export default TimelineContainer