import React from "react"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"

import YoutubeVideoPanel from "../../internal/youtubeVideoPanel/YoutubeVideoPanel"
import IspringEmbedVideoPanel from "../../internal/ispringEmbedVideoPanel/IspringEmbedVideoPanel"

interface PrimaryAssetBlok extends SBPanel {
}
interface PrimaryAssetPanelProps {
  blok: PrimaryAssetBlok,
  story?: any,
}
function PrimaryAssetPanel ({ blok, story }:PrimaryAssetPanelProps):JSX.Element {
  if(story.content.ispring_url) {
    let video_uuid = story.content.ispring_url.substring(story.content.ispring_url.lastIndexOf('/'))
    return (
      <PanelWrapper blok={blok}>
        <div className="container">
          <IspringEmbedVideoPanel
            video_uuid={video_uuid} />
        </div>
      </PanelWrapper>
    )
  } else if(story.content.video_url) {
    return (
      <PanelWrapper blok={blok}>
        <div className="container">
          <YoutubeVideoPanel
            videoId={story.content.video_url} />
        </div>
      </PanelWrapper>
    )
  }
  return ( <div>Video not defined</div> )
}

export default PrimaryAssetPanel