import React, { createRef, useState } from "react"
// @ts-ignore
import Recaptcha from "react-google-recaptcha"
// @ts-ignore
import jQuery from "jquery"

import { EncodeNetlifyFormData } from "../../../siteUtils/helpers"

// @ts-ignore
import * as styles from "./FeedbackPanelForm.module.css"

interface FeedbackFormProps {
  modalId: string
}
function FeedbackPanelForm({ modalId }:FeedbackFormProps) {
  const honeypotRef = createRef<HTMLInputElement>(),
        formRef = createRef<HTMLFormElement>(),
        nameRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),
        feedbackRef = createRef<HTMLTextAreaElement>(),
        [recaptchaValue, setRecaptchaValue] = useState<string|null>(null)

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true),
        [isLoading, setIsLoading] = useState<boolean>(false)

  function handleSubmit(e:React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsLoading(true)

    let encodedFormData = EncodeNetlifyFormData({
      "form-name": formRef.current?.getAttribute("name"),
      "name": nameRef.current?.value || "",
      "email": emailRef.current?.value || "",
      "feedback": feedbackRef.current?.value || "",
      "g-recaptcha-response": recaptchaValue,
      "honeypot": honeypotRef.current?.value || ""
    })
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: encodedFormData
    }).then((response)=>{
      setIsLoading(false)
      if(response.ok) {
        jQuery(`#${modalId}`).remove()
        jQuery('.modal-backdrop').remove()
        jQuery("body").removeAttr("style")
      }
    }).catch((err)=>{
      console.error(err)
      setIsLoading(false)
    })
  }

  function handleRecaptchaChange(e:any) {
    if(e) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
    setRecaptchaValue(e)
  }

  return (
    <div>
      <div className="modal fade" id={modalId} data-bs-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className={`modal-header ${styles.header}`}>
              Provide feedback about this article
            </div>
            <form ref={formRef} name="feedback" data-netlify="true" data-netlify-recaptcha="true" netlify-honeypot="honeypot" method="POST" onSubmit={handleSubmit}>
              <div className="modal-body">
                <noscript>
                  <p>This form will not work with Javascript disabled</p>
                </noscript>
                <div className={styles.formElement}>
                  <label htmlFor="name">Name</label>
                  <input ref={nameRef} id="name" name="name" type="text" disabled={isLoading} />
                </div>
                <div className={styles.formElement}>
                  <label htmlFor="email">Email</label>
                  <input ref={emailRef} id="email" name="email" type="email" disabled={isLoading} />
                </div>
                <div className={styles.formElement}>
                  <label htmlFor="feedback">How can we make this page more helpful?</label>
                  <textarea ref={feedbackRef} id="feedback" name="feedback" rows={6} disabled={isLoading} />
                </div>
                {/* Honeypot Field : If a value is entered this will fail the netlify form (https://docs.netlify.com/forms/spam-filters/#honeypot-field) */}
                <div className="hidden">
                  <label htmlFor="honeypot">Do not enter this field if you are a human</label>
                  <input ref={honeypotRef} id="honeypot" name="honeypot" />
                </div>
                <input type="hidden" id="form-name" name="form-name" value="feedback" />
                <Recaptcha
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  size="normal"
                  id="recaptcha-google"
                  onChange={handleRecaptchaChange} />
              </div>
              <div className={`modal-footer ${styles.footer}`}>
                <button type="submit" disabled={submitDisabled || isLoading}>Submit</button>
                <button type="button" disabled={isLoading} data-bs-dismiss="modal">Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackPanelForm