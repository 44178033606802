import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { RenderComponents } from "../../DynamicComponent"
import TimelineContainer, { TimelineItem } from "../../internal/timelineContainer/TimelineContainer"
import { resolveRichText } from "../../../siteUtils/richText"


interface TimelineComponent extends SBPanel {
  title: Richtext,
  text_alignment: string,
  timeline_color: SBNativeColorPicker,
  timeline_items: Array<TimelineItem>,
  item_sizing: string,
  cta_button: Array<SBBlok>
}
interface TimelineProps {
  blok: TimelineComponent
}
function TimelinePanel({ blok }:TimelineProps):JSX.Element {

  let buttons:Array<JSX.Element> = []
  if(blok.cta_button) {
    buttons = RenderComponents(blok.cta_button)
  }
  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        {blok.title ? <div dangerouslySetInnerHTML={resolveRichText(blok.title)} className={`rich-text-handler ${blok.text_alignment}`} style={{marginBottom: 30}} /> : null}
        <TimelineContainer
          items={blok.timeline_items}
          item_sizing={blok.item_sizing}
          timeline_color={blok.timeline_color ? blok.timeline_color.color : ""} />
        {blok.cta_button ? <div style={{marginTop: 30, textAlign: "center"}}>{buttons}</div> : null}
      </div>
    </PanelWrapper>
  )
}

export default TimelinePanel