

function NormalizePageURL (path:string):string {
  let newPath = path
  if(!newPath.startsWith('/')) {
    newPath = `/${newPath}`
  }
  if(!newPath.endsWith('/')) {
    newPath = `${newPath}/`
  }
  if(newPath === "/home/") {
    return "/"
  }
  return newPath
}


function EncodeNetlifyFormData(data:any):string {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
}


export { 
  NormalizePageURL,
  EncodeNetlifyFormData
 }