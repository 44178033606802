import React from "react"

interface StoryblokImageProps {
  image: SBImage,
  backup_alt?: string,
  width?: number | string,
  height?: number | string,
  children?: any
}


function getImageProps(image: string){
  let url = image;
  const validImageUrlPattern = /^(https?:)?\/\/a.storyblok.com\/f\/*[0-9]+\/*[0-9]*x*[0-9]*\/[A-Za-z0-9]+\/[\S]+\.[a-zA-Z]+/
  const storyblokRegex = /^(https?:)?\/\/a.storyblok.com\//
  url = validImageUrlPattern.test(url) ? url : ""

  if (url === "") {
    return false
  }

  let originalPath = url.replace(storyblokRegex, '')

  let [, , dimensions, , filename] = originalPath.split('/')
  let [width, height] = dimensions.split('x').map((num) => parseInt(num, 10))
  let [, extension] = filename.split('.')

  let aspectRatio = width / height
  let metadata = { dimensions: { width, height, aspectRatio } }

  return {
    originalPath,
    extension,
    metadata
  }

}

function StoryblokImage({ image, backup_alt, width, height, children }:StoryblokImageProps):JSX.Element {
  if(!image) {
    if(children) {
      return children
    } else {
      return (<span>INVALID STORYBLOK IMAGE</span>)
    }
  }
  let url = normalizeURL(image.filename)
  let displayWidth = width ? width : "100%"
  let displayHeight = height? height: "auto"
  let props = image ? getImageProps(image.filename) : null
  if(props && props.extension !== 'svg'){
    displayWidth = width ? width : props.metadata.dimensions.width
    displayHeight = height ? height : props.metadata.dimensions.height
  }

  
  return ( <img src={url} alt={image.alt || backup_alt} width={displayWidth} height={displayHeight} /> )
  // return ( <img src={url} alt={image.alt} width={width} height={height} /> )
}

function normalizeURL(url:string):string {
  // SB Image can't handle svg images.
  if(!url){return ""}
  if(url.endsWith('.svg')) {
    return url
  }
  let current = url+'/m'
  
  // If no other changes to the url atleast add server-side webp detection
  if(current.endsWith('/m')) {
    current += '/'
  }
  return current
}

export { normalizeURL }
export default StoryblokImage