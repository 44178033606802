import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../siteUtils/richText"

interface TextWidgetComponent {
  text:Richtext
}
interface TextWidgetProps {
  blok:TextWidgetComponent
}
function TextWidget({ blok }:TextWidgetProps):JSX.Element {
  return (
    <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.text)} />
  )
}

export default TextWidget