// extracted by mini-css-extract-plugin
export var circle = "TimelineItem-module--circle--CWIAu";
export var circleLink = "TimelineItem-module--circleLink--5Aag3";
export var circleWrapper = "TimelineItem-module--circleWrapper--cY3dn";
export var content = "TimelineItem-module--content--+FvKO";
export var description = "TimelineItem-module--description--cnL0T";
export var dot = "TimelineItem-module--dot--VdYSU";
export var leftLine = "TimelineItem-module--leftLine--XRY02";
export var rightLine = "TimelineItem-module--rightLine--XezFc";
export var thumbnail = "TimelineItem-module--thumbnail--osr-I";
export var timeline = "TimelineItem-module--timeline--0xbFY";
export var timeline_active = "TimelineItem-module--timeline_active--2yis1";
export var timeline_complete = "TimelineItem-module--timeline_complete--9W1Ez";
export var title = "TimelineItem-module--title--lakl+";
export var wrapper = "TimelineItem-module--wrapper--w4m5g";