import React, { useState } from "react"
import { Richtext } from "storyblok-js-client"
import { Modal } from "react-bootstrap"

import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { VideoTriangleLarge } from "../../internal/videoTriangle/VideoTriangle"
import { resolveRichText } from "../../../siteUtils/richText"
import { GeneralSizeKeyToPaddingNumber } from "../../../siteUtils/datasources"

// @ts-ignore
import * as styles from "./VideoBannerPanel.module.css"

interface VideoBannerComponent extends SBPanel {
  content: Richtext,
  text_alignment: string,
  text_padding: string,
  text_color: SBNativeColorPicker,
  background_image: SBImage,
  foreground_image: SBImage,
  ispring_url: string
}
interface VideoBannerProps {
  blok: VideoBannerComponent
}
function VideoBannerPanel({ blok }:VideoBannerProps):JSX.Element {

  const padding = GeneralSizeKeyToPaddingNumber(blok.text_padding)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
 
  return (
    <PanelWrapper blok={blok}>
      <div className={styles.wrapper}>
        <div className={styles.background_image}>
          <StoryblokImage image={blok.background_image} />
        </div>
        <div className={`container ${styles.content}`}>
          <div className={`row ${styles.videoBannerContainer}`}>
          <div className="col-lg-6">
          <div dangerouslySetInnerHTML={resolveRichText(blok.content)} className={`rich-text-handler ${blok.text_alignment}`} style={{paddingTop: padding, paddingBottom: padding, color: blok.text_color ? blok.text_color.color : ""}} />
          </div>
          <div className="col-lg-5">
          <div className={styles.foreground_image}>
            <button onClick={()=>{setShow(true)}} className={styles.modalTrigger}>
              <StoryblokImage image={blok.foreground_image} width={520} />
              {/* {true ? (
                <VideoTriangleLarge />
              ) : null} */}
            </button>
          </div>
          </div>
          </div>
        </div>
      </div>
      <Modal 
        className={"videoModal"}
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <button type="button" className={"btn-close"} aria-label="Close" onClick={() => {setShow(false)}}></button>
        </Modal.Header>
        <Modal.Body>
          <div className={"videoWrapper"}>
          <iframe
            width="920"
            height="518"
            src={blok.ispring_url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </PanelWrapper>
  )
}

export default VideoBannerPanel