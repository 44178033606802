import React from "react"
import { BackgroundKeyToColor, GeneralSizeKeyToPaddingNumber } from "../../siteUtils/datasources"

import DynamicComponent from "../../components/DynamicComponent"

interface FooterComponent {
  background_color: string,
  padding_top: string,
  padding_bottom: string,

  text_color_override: SBNativeColorPicker,

  content: Array<any>
}
interface FooterProps {
  blok: FooterComponent,
  editable?: boolean
}
function Footer({ blok, editable }:FooterProps):JSX.Element {
  const backgroundColor = BackgroundKeyToColor(blok.background_color),
        paddingTop = GeneralSizeKeyToPaddingNumber(blok.padding_top),
        paddingBot = GeneralSizeKeyToPaddingNumber(blok.padding_bottom)

  return (
    <footer style={{backgroundColor: backgroundColor, paddingTop: paddingTop, paddingBottom: paddingBot, color: blok.text_color_override ? blok.text_color_override.color : ""}}>
      <div className="container">
        <div className="row">
          {blok.content ? blok.content.map((contentBlok)=>(
            <div className="col" key={contentBlok._uid}>
              <DynamicComponent blok={contentBlok} editable={editable} />
            </div>
          )) : null}
        </div>
      </div>
    </footer>
  )
}

export default Footer