import React from "react"
import { Helmet } from "react-helmet"

function NoIndexUnlessProduction() {
  if(process.env.GATSBY_ENVIRONMENT === "PRODUCTION") {
    return null
  } else {
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  }
}

export default NoIndexUnlessProduction