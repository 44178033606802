import React from "react"
// @ts-ignore
import SbEditable from "storyblok-react"

// Panels
import Header from "./header/Header"
import Breadcrumbs from "./breadcrumbs/Breadcrumbs"
import Footer from "./footer/Footer"

import CtaTextPanel from "../components/panels/CtaTextPanel/CtaTextPanel"
import ContentChangeWrapper from "../components/wrappers/contentChangeWrapper/ContentChangeWrapper"

const Components:any = {
  // Panels
  "header": Header,
  "breadcrumbs": Breadcrumbs,
  "footer": Footer,
  "cta_text_panel": CtaTextPanel,

  // Wrappers
  "content_change_wrapper": ContentChangeWrapper,
}

interface GlobalDynamicComponentProps {
  blok: any,
  editable?: boolean,
  slug?: string
}
const GlobalDynamicComponent = ({ blok, editable, slug }:GlobalDynamicComponentProps):JSX.Element => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    if(editable) {
      return (<SbEditable content={blok}><Component blok={blok} key={blok._uid} slug={slug}/></SbEditable>)
    } else {
      return <Component blok={blok} slug={slug} editable={editable} />
    }
  }
 
  return  (
    <p>{`Component [${blok.component}] does not exist.`}</p>
  )
}

export function RenderGlobalComponents(list:Array<any>, editable:boolean, slug?:string):Array<JSX.Element> {
  return list.map((blok:any)=>{
    return (<GlobalDynamicComponent key={blok._uid} blok={blok} editable={editable} slug={slug} />)
  })
}

export default RenderGlobalComponents