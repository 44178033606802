import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../siteUtils/richText"

interface RichTextComponent extends SBPanel {
  content: Richtext
}
interface RichTextPanelProps {
  blok: RichTextComponent
}
function RichTextPanel({ blok }:RichTextPanelProps):JSX.Element {

  return (
    <PanelWrapper blok={blok}>
      <div className="container rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.content)} />
    </PanelWrapper>
  )
}

export default RichTextPanel