import React from "react"


import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import AssetListingPanel from "../../internal/assetListingPanel/AssetListingPanel"

import useAssets from "../../../hooks/useAssets"

interface AssetReference {
  asset: string
}
interface AssetCuratedListingComponent extends SBPanel {
  label: string,
  assets: Array<AssetReference>
}
interface AssetCuratedListingProps {
  blok: AssetCuratedListingComponent
}
function AssetCuratedListingPanel({ blok }:AssetCuratedListingProps) {
  const allAssets = useAssets()

  const assetList:Array<Asset> = []
  if(blok.assets) {
    blok.assets.forEach((assetRef)=>{
      let found = allAssets.find((v)=>{return v.uuid === assetRef.asset})
      if(found) {
        assetList.push(found)
      }
    })
  }

  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <AssetListingPanel 
          canMinimize={false}
          label={blok.label}
          assets={assetList}
          defaultOpen={true} />
      </div>
    </PanelWrapper>
  )
}

export default AssetCuratedListingPanel