import React from "react"
import { Richtext } from "storyblok-js-client"

import AssetCard from "../../internal/assetCard/AssetCard"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../siteUtils/richText"
import useAssets from "../../../hooks/useAssets"

interface RelatedAssetsComponent extends SBPanel {
  title: Richtext,
  related_assets: Array<string>
}
interface RelatedAssetsProps {
  blok: RelatedAssetsComponent
}
function RelatedAssetsPanel({ blok }:RelatedAssetsProps):JSX.Element {
  const assets = useAssets()

  const assetItems:Array<Asset> = []

  if(blok.related_assets) {
    for(let i = 0; i < blok.related_assets.length; i++) {
  
      let video = assets.find((vid)=>{ return vid.uuid === blok.related_assets[i] })
  
      if(video) {
        assetItems.push(video)
      }
    }
  }

  return (
    <PanelWrapper blok={blok}>
      <div className="container container-width-buster">
        {blok.title ? <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.title)} /> : null}
        <div className="row">
          {assetItems.map((asset)=>(
            <div key={asset.uuid} className="col-md-4">
              <AssetCard
                thumbnail={asset.thumbnail}
                name={asset.name}
                asset_type={asset.asset_type}
                full_slug={asset.full_slug} />
            </div>
          ))}
        </div>
      </div>
    </PanelWrapper>
  )
}

export default RelatedAssetsPanel