import React, { useState, useEffect} from "react"

import { ReadEnactLearningCookie } from "../../../siteUtils/cookies"
import { RenderComponents } from "../../DynamicComponent"

interface ContentChangeWrapper {
  condition: string,
  condition_valid_content?: Array<SBPanel>,
  condition_invalid_content?: Array<SBPanel>,
}
interface ContentChangeProps {
  blok: ContentChangeWrapper,
  story: any
}
function ContentChangeWrapper({ blok, story }:ContentChangeProps):JSX.Element {
  const [isEnactUser,setIsEnactUser] = useState<boolean>(false)

  useEffect(()=>{
    const token = ReadEnactLearningCookie()
    if(token) {
      setIsEnactUser(true)
    }
  },[])

  return (
    <div>
      {isEnactUser ? 
        RenderComponents(blok.condition_valid_content || [], story) : 
        RenderComponents(blok.condition_invalid_content || [], story)
      }
    </div>
  )
}

export default ContentChangeWrapper