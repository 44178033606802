import React, { useState } from "react"
import { Link } from "gatsby"

import { RenderComponents } from "../../components/DynamicComponent"
import StoryblokImage from "../../components/internal/storyblokImage/StoryblokImage"
import useTopics from "../../hooks/useTopics"

// @ts-ignore
import * as styles from "./Header.module.css"

interface HeaderComponent {
  logo_background_color: SBNativeColorPicker,
  logo_border_color: SBNativeColorPicker,
  logo: SBImage,
  additional_buttons: Array<SBBlok>
}
interface HeaderProps {
  blok: HeaderComponent
}
function Header({ blok }:HeaderProps):JSX.Element {
  const topics = useTopics().filter((category)=>{return category.show_in_nav ? true : false})

  const [showNavDropdown,setShowNavDropdown] = useState<boolean>(false)

  const additional_buttons = RenderComponents(blok.additional_buttons)

  return (
    <div className={styles.navWrapper}>
      <nav>
        <div className={`${styles.nav} ${showNavDropdown ? styles.nav_visible : ""}`}>
          <Link to="/">
            <div className={styles.logo} style={{backgroundColor: blok.logo_background_color ? blok.logo_background_color.color : "", borderColor: blok.logo_border_color ? blok.logo_border_color.color : ""}}>
              <StoryblokImage image={blok.logo} width={120} height={49}/>
            </div>
          </Link>
          <div className={styles.navbar_links}>
            <div className={styles.navActions}>
              <button>Topics&nbsp;<div className={`${styles.dropdownArrow}`}></div></button>
              <div className={`${styles.dropdown}`}>
                <ul>
                  {topics.map(topic=>(
                    <li key={topic.uuid}><Link to={`/${topic.full_slug}`}>{topic.nav_label || topic.name}</Link></li>
                  ))}
                </ul>
              </div>
            </div>
            {additional_buttons.map((btn)=>(<div className={styles.navBtnWrapper} key={btn.key}>{btn}</div>))}
          </div>
          <div className={styles.hamburgerWrapper}>
              <div style={{width: 40, height: 40}}>
                <div className={`${styles.hamburger} ${showNavDropdown ? styles.cross : ""}`} onClick={()=>{setShowNavDropdown(!showNavDropdown)}}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
        </div>
      </nav>
    </div>
  )
}

export default Header