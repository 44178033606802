import { graphql, useStaticQuery } from "gatsby"

function NormalizePageURL (path:string):string {
  let newPath = path
  if(!newPath.startsWith('/')) {
    newPath = `/${newPath}`
  }
  if(!newPath.endsWith('/')) {
    newPath = `${newPath}/`
  }
  if(newPath === "/home/") {
    return "/"
  }
  return newPath
}

function useBreadcrumbData():Array<BreadcrumbData> {
  const results = useStaticQuery(graphql`
    query GetBreadcrumbData {
      allStoryblokEntry(
        filter: {field_component: {in: ["standard_page","asset","topic"]}}
      ) {
        edges {
          node {
            uuid
            name
            content
            full_slug
          }
        }
      }
    }
  `)
  let data:Array<BreadcrumbData> = results.allStoryblokEntry.edges.map((edge:any)=>{
    return edge.node
  }).map((item:any)=>{
    let content = JSON.parse(item.content)
    let newItem:BreadcrumbData = {
      uuid: item.uuid,
      full_slug: NormalizePageURL(item.full_slug),
      name: content.name,
      exclude_from_breadcrumbs: content.exclude_from_breadcrumbs,
      breadcrumb_label: content.breadcrumb_label
    }
    return newItem
  })
  
  return data
}

export default useBreadcrumbData