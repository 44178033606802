//
// Youtube Video Panel
// https://developers.google.com/youtube/player_parameters#Parameters
//
import React from "react"

// @ts-ignore
import * as styles from "./YoutubeVideoPanel.module.css"

interface VideoPanelProps {
  videoId?:string,
  autoplay?:boolean,
  startAtSeconds?: number,
  allowFullScreen?: boolean
}
function YoutubeVideoPanel({ videoId, autoplay, startAtSeconds, allowFullScreen }:VideoPanelProps):JSX.Element {
  if(!videoId) {
    return (<div></div>)
  }
  let query:Array<string> = ["rel=0"]
  if(autoplay) {
    query.push(`autoplay=1`)
  }
  if(startAtSeconds) {
    query.push(`start=${startAtSeconds}`)
  }

  const src = `https://www.youtube.com/embed/${videoId}${query && query.length > 0 ? `?${query.join('&')}` : ""}`

  return (
    <div className={styles.videoWrapper}>
      <iframe src={src} width="560" height="349" allowFullScreen={allowFullScreen} />
    </div>
  )
}

export default YoutubeVideoPanel