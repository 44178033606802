import React from "react"
import { Link } from "gatsby"

function NormalizePageURL (path:string, anchor?:string):string {
  let newPath = path
  if(!newPath.startsWith('/')) {
    newPath = `/${newPath}`
  }
  if(!newPath.endsWith('/')) {
    newPath = `${newPath}/`
  }
  if(anchor) {
    newPath = `${newPath}#${anchor}`
  }
  return newPath
}

interface StoryblokLinkProps {
  link?: SBLink,
  text?: string,
  withClass?: string,
  children?: any,
  newWindow?: boolean
}

const StoryblokLink = ({ link, text, withClass, children, newWindow }:StoryblokLinkProps):JSX.Element => {
  if(!link) {
    if(children) {
      return <span className={withClass}>{children}</span>
    }
    return <span className={withClass}>{text}</span>
  }
  const target = newWindow ? "_blank" : "_self"

  if(link.linktype === "asset") {
    if(children) {
      return <a className={withClass} href={link.cached_url} target={target}>{children}</a>
    }
    else {
      return <a className={withClass} href={link.cached_url} target={target}>{text}</a>
    }
  }
  if(link.linktype === "url" && link.url) {
    if(children) {
      return <a className={withClass} href={link.url} target={target}>{children}</a>
    }
    else {
      return <a className={withClass} href={link.url} target={target}>{text}</a>
    }
  }
  if(link.linktype === "story") {
    if(children) {
      return <Link className={withClass} to={NormalizePageURL(link.cached_url, link.anchor)} target={target}>{children}</Link>
    }
    else {
      return <Link className={withClass} to={NormalizePageURL(link.cached_url, link.anchor)} target={target}>{text}</Link>
    }
  }

  console.warn("Improper SB Link configuration.")
  return <span className={withClass}>SB Link Error</span>
}

export default StoryblokLink