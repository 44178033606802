import { graphql, useStaticQuery } from "gatsby"

function useTopics():Array<Topic> {
  const results = useStaticQuery(graphql`
    query GetVideoCategories {
      allStoryblokEntry(
        filter: {field_component: {eq: "topic"}}
      ) {
        edges {
          node {
            uuid
            name
            content
            full_slug
          }
        }
      }
    }
  `)
  let data = results.allStoryblokEntry.edges.map((edge:any)=>{
    return edge.node
  }).map((item:any)=>{
    let content = JSON.parse(item.content)
    let newItem:Topic = {
      uuid: item.uuid,
      full_slug: item.full_slug,
      name: content.name,
      page_content: content.page_content,
      show_in_nav: content.show_in_nav,
      nav_label: content.nav_label,
      navigation_preferred_order: content.navigation_preferred_order
    }
    return newItem
  }).sort((a:Topic,b:Topic)=>{
    if(a.navigation_preferred_order) {
      if(b.navigation_preferred_order) {
        return a.navigation_preferred_order > b.navigation_preferred_order ? 1 : -1
      } else {
        return 1
      }
    } else if(b.navigation_preferred_order) {
      return -1;
    }
    return 0;
  })
  
  return data
}

export default useTopics