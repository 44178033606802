import React from "react"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../siteUtils/richText"
import { Richtext } from "storyblok-js-client"

import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import { BackgroundKeyToColor } from "../../../siteUtils/datasources"


function SizeKeyToWidthValues(key:string) {
  switch(key) {
    case "eight_four":
      return [8,4]
    case "seven_five":
      return [7,5]
    case "six_six":
      return [6,6]
    case "five_seven":
      return [5,7]
    case "four_eight":
      return [4,8]
    default:
      return [6,6]
  }
}


interface TwoColumnTextImageComponent extends SBPanel {
  text: Richtext,
  text_side: string,
  text_background_color: string,
  image: SBImage,
  column_sizes: string,
  image_styles: string
}
interface TwoColumnTextImageProps {
  blok: TwoColumnTextImageComponent
}
function TwoColTextImagePanel({ blok }:TwoColumnTextImageProps):JSX.Element {
  const textBackgroundColor = BackgroundKeyToColor(blok.text_background_color)
  const colSizes = SizeKeyToWidthValues(blok.column_sizes)

  const textColumn = (
    <div className={`col-lg-${colSizes[0]}`} style={{backgroundColor: textBackgroundColor}}>
      <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.text)} />
    </div>
  )
  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <div className="row">
        {/* <div className="row g-0"> */}
          {blok.text_side === "left" ? textColumn : null}
          <div className={`col-lg-${colSizes[1]}`}>
            <div className={`${blok.image_styles ? blok.image_styles : ""}`}>
              <StoryblokImage image={blok.image} />
            </div>
          </div>
          {blok.text_side === "right" ? textColumn : null}
        </div>
      </div>
    </PanelWrapper>
  )
}

export default TwoColTextImagePanel