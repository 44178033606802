import React from "react"
import { Richtext } from "storyblok-js-client"

import { RenderComponents } from "../../DynamicComponent"
import { resolveRichText } from "../../../siteUtils/richText"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"


interface CtaTextComponent extends SBPanel {
  text: Richtext,
  text_alignment: string,
  button_alignment: string,
  cta_buttons: Array<any>
}
interface CtaTextProps {
  blok: CtaTextComponent
}
function CtaTextPanel({ blok }:CtaTextProps):JSX.Element {
  
  const cta_items = blok.cta_buttons ? RenderComponents(blok.cta_buttons) : []

  return (
    <PanelWrapper blok={blok}>
      {blok.text ? <div className={`${blok.text_alignment || ""}`} dangerouslySetInnerHTML={resolveRichText(blok.text)} /> : null}
      {cta_items && cta_items.length > 0 ? (
        <div className={`${blok.button_alignment || ""}`}>
          {cta_items}
        </div>
      ) : null}
    </PanelWrapper>
  )
}

export default CtaTextPanel