import React from "react"
import { Link } from "gatsby"

import useBreadcrumbData from "../../hooks/useBreadcrumbData"
// @ts-ignore
import * as styles from "./Breadcrumbs.module.css"

function NormalizePageURL (path:string):string {
  let newPath = path
  if(!newPath.startsWith('/')) {
    newPath = `/${newPath}`
  }
  if(!newPath.endsWith('/')) {
    newPath = `${newPath}/`
  }
  if(newPath === "/home/") {
    return "/"
  }
  return newPath
}

interface Crumb {
  label: string,
  link: string
}
interface BreadcrumbProps {
  blok: SBPanel,
  slug: string
}
function Breadcrumbs({ blok, slug }:BreadcrumbProps):JSX.Element {
  const breadcrumbData = useBreadcrumbData().filter((d:BreadcrumbData)=>{ return d.exclude_from_breadcrumbs !== true})

  let crumbs:Array<Crumb> = []

  let path = ``
  let crumbItems:Array<string> = slug.split('/')

  for(let i = 0; i < crumbItems.length; i++) {
    path = `${path}${crumbItems[i]}/`
    if(path.startsWith('//')) {
      path = path.substring(1)
    }
    path = NormalizePageURL(path)

    let data = breadcrumbData.find((d:BreadcrumbData)=>{
      return d.full_slug === path
    })

    if(data) {
      crumbs.push({
        label: data.breadcrumb_label || data.name,
        link: path
      })
    }
  }


  return (
    <div className={styles.breadcrumbWrapper}>
      <div className="container">
        <div className={styles.crumbs}>
          {crumbs.map((crumb, idx)=>(
            <span key={idx} className={styles.crumb}>
              {idx < crumbs.length -1 ? (
                <Link to={crumb.link}>{crumb.label}<span>&nbsp;&gt;</span></Link>
              ) : crumb.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs