import React from "react"
import { Richtext } from "storyblok-js-client"

import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import IspringEmbedVideoPanel from "../../internal/ispringEmbedVideoPanel/IspringEmbedVideoPanel"

import { resolveRichText } from "../../../siteUtils/richText"
import { GeneralSizeKeyToPaddingNumber } from "../../../siteUtils/datasources"

// @ts-ignore
import * as styles from "./HeroPanel.module.css"

interface HeroComponent extends SBPanel {
  background_image: SBImage,

  left_text: Richtext,
  left_text_color: SBNativeColorPicker,
  left_text_alignment: string,
  left_text_padding: string,
  
  right_image: SBImage,
  ispring_url: string,
  right_text: Richtext,
  right_text_color: SBNativeColorPicker,
  right_text_alignment: string,
  right_text_padding: string,
}
interface HeroProps {
  blok: HeroComponent
}
function HeroPanel({ blok }:HeroProps):JSX.Element {

  const leftPadding = GeneralSizeKeyToPaddingNumber(blok.left_text_padding),
        rightPadding = GeneralSizeKeyToPaddingNumber(blok.right_text_padding)
 
  return (
    <PanelWrapper blok={blok}>
      <div className={styles.wrapper}>
        <div className={styles.background_image}>
          <StoryblokImage image={blok.background_image} />
        </div>
        <div className={`container ${styles.content}`}>
          <div className={`row ${styles.videoBannerContainer}`}>
            <div className="col-lg-6">
              <div dangerouslySetInnerHTML={resolveRichText(blok.left_text)} className={`rich-text-handler ${blok.left_text_alignment}`} style={{paddingTop: leftPadding, paddingBottom: leftPadding, color: blok.left_text_color ? blok.left_text_color.color : ""}} />
            </div>
            <div className="col-lg-5">
              {blok.right_image ? (
                <div className={styles.foreground_image}>
                  <StoryblokImage image={blok.right_image} width={520} />
                </div>
              ) : null}
              {blok.right_text ? (
                <div dangerouslySetInnerHTML={resolveRichText(blok.right_text)} className={`rich-text-handler ${blok.right_text_alignment}`} style={{paddingTop: rightPadding, paddingBottom: rightPadding, color: blok.right_text_color ? blok.right_text_color.color : ""}} />
              ) : null}
              {blok.ispring_url ? (
                <IspringEmbedVideoPanel video_uuid={blok.ispring_url.substring(blok.ispring_url.lastIndexOf('/'))} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </PanelWrapper>
  )
}

export default HeroPanel