import React from "react"
import { Link } from "gatsby"

import StoryblokImage from "../storyblokImage/StoryblokImage"
import { VideoTriangle } from "../videoTriangle/VideoTriangle"

import { NormalizePageURL } from "../../../siteUtils/helpers"
// @ts-ignore
import * as styles from "./AssetCard.module.css"
// @ts-ignore
import defaultVideo from "../../../assets/images/default_video.png"
// @ts-ignore
import newVideoTag from "../../../assets/images/new_video_overlay.png"

interface AssetCardProps {
  thumbnail: SBImage,
  name: string,
  asset_type: string,
  short_description?: string,
  full_slug: string,
  show_new_tag?: boolean
}
function AssetCard({asset_type, thumbnail, name, short_description, full_slug, show_new_tag}:AssetCardProps):JSX.Element {
  return (
    <div>
      <Link to={NormalizePageURL(full_slug)}>
        <div className={styles.thumbnail}>
          {thumbnail &&thumbnail.id ? (
            <StoryblokImage image={thumbnail} backup_alt={name} />
          ) : (
            <img alt="asset-thumbnail" width={200} height={150} src={defaultVideo} />
          )}
          {show_new_tag ? <img className={styles.newVideoTag} alt="new video overlay banner" width={133} height={28} src={newVideoTag} /> : null}
          {/* {asset_type === "video" ? <VideoTriangle /> : null} */}
        </div>
      </Link>
      <div className={styles.content}>
        {/* {name ? <Link to={NormalizePageURL(full_slug)} className="link-default-text"><div className={styles.name}>{name}</div></Link> : null} */}
        {/* {short_description ? <div className={styles.description}>{short_description}</div> : null} */}
        {/* {cta_label ? <div className={styles.cta}><Link to={NormalizePageURL(full_slug)} className="iqs-btn iqs-btn-solid-primary">{cta_label}</Link></div> : null} */}
      </div>
    </div>
  )
}

export default AssetCard