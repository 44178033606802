//
// Ispring Embed Video Panel
//
import React from "react"

// @ts-ignore
import * as styles from "./IspringEmbedVideoPanel.module.css"

interface IspringEmbedVideoProps {
  video_uuid?:string
}
function IspringEmbedVideoPanel({ video_uuid }:IspringEmbedVideoProps):JSX.Element {
  if(!video_uuid) {
    return (<div></div>)
  }

  const embedCode = `<iframe src="https://new-acc-space-10528.ispring.com/s/embed_player/${video_uuid}" width="560" height="315" frameborder="0" border="0" scrolling="auto" allowtransparency="true" allowfullscreen="1" style="border: none; background-color: transparent;"></iframe>`

  return (
    <div className={styles.videoWrapper} dangerouslySetInnerHTML={{__html: embedCode}} >
    </div>
  )
}

export default IspringEmbedVideoPanel