import React from "react"

import StoryblokLink from "../../internal/storyblokLink/StoryblokLink"
import { StyleKeyToClassName } from "../../../siteUtils/datasources"

interface IqsButtonComponent {
  label: string,
  link: SBLink,
  new_window: boolean,
  style: string
}
interface IqsButtonProps {
  blok: IqsButtonComponent
}
function IqsButton({ blok }:IqsButtonProps):JSX.Element {  
  return (
    <StoryblokLink link={blok.link} withClass={StyleKeyToClassName(blok.style)} newWindow={blok.new_window}>
      {blok.label}
    </StoryblokLink>
  )
}

export default IqsButton