
function BackgroundKeyToColor(key:string):string {
  switch(key) {
    case "white":
      return "#fff"
    case "light":
      return "#ededed"
    case "dark":
      return "#8a8a8a"
    case "black":
      return "#000"
    default:
      return "transparent"
  }
}

function HeightSizeKeyToBarHeight(key: string):string {
  switch(key) {
    case "75_percent":
      return "75%"
    case "50_percent":
      return "50%"
    case "33_percent":
      return "33%"
    case "25_percent":
      return "25%"
    case "10_percent":
      return "10%"
    default:
      return "50%"
  }
}

function GeneralSizeKeyToPaddingNumber(key: string):number {
  switch(key) {
    case "small":
      return 30
    case "medium":
      return 60
    case "large":
      return 90
    default:
      return 0
  }
}

function StyleKeyToClassName(key: string):string {
  switch(key) {
    case "primary":
      return "iqs-btn iqs-btn-primary"
    case "solid-primary":
      return "iqs-btn iqs-btn-solid-primary"
    case "secondary":
      return "iqs-btn iqs-btn-secondary"
    case "solid-secondary":
      return "iqs-btn iqs-btn-solid-secondary"
    case "default-text-link":
      return "iqs-btn iqs-btn-default-simple-text"
    default:
      return "iqs-btn"
  }
}

export { 
  BackgroundKeyToColor, 
  HeightSizeKeyToBarHeight, 
  GeneralSizeKeyToPaddingNumber,
  StyleKeyToClassName
}