import { graphql, useStaticQuery } from "gatsby"

function useVideos():Array<Asset> {
  const results = useStaticQuery(graphql`
    query GetVideos {
      allStoryblokEntry(
        filter: {field_component: {eq: "asset"}}
      ) {
        edges {
          node {
            uuid
            name
            content
            full_slug
          }
        }
      }
    }
  `)
  let data = results.allStoryblokEntry.edges.map((edge:any)=>{
    return edge.node
  }).map((item:any)=>{
    let content = JSON.parse(item.content)
    let newItem:Asset = {
      uuid: item.uuid,
      full_slug: item.full_slug,
      
      name: content.name,
      asset_type: content.asset_type,
      short_description: content.short_description,
      thumbnail: content.thumbnail,
      video_url: content.video_url,
      topic: content.topic,
      cta_label: content.cta_label,
      related_videos: content.related_videos,
      new_until_date: content.new_until_date ? new Date(content.new_until_date) : undefined,

      page_content: content.page_content
    }
    return newItem
  })
  
  return data
}

export default useVideos