import React, { useState } from "react"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import FeedbackPanelForm from "../../forms/FeedbackPanelForm/FeedbackPanelForm"

// @ts-ignore
import * as styles from "./FeedbackPanel.module.css"

interface FeedbackComponent extends SBPanel {
  question_text: string,
  feedback_text: string,
  description_text: string
}
interface FeedbackProps {
  blok: FeedbackComponent,
  story: SBStory
}
function FeedbackPanel({ blok, story }:FeedbackProps):JSX.Element {
  const [showReasonDropdown, setShowReasonDropdown] = useState<boolean>(false),
        [showSimpleResponse, setShowSimpleResponse] = useState<boolean>(true),
        [simpleResponseIsLoading, setSimpleResponseIsLoading] = useState<boolean>(false)
  const MODAL_ID = "feedback_modal"


  function SaveSimpleResponse(data:any) {
    setSimpleResponseIsLoading(true)
    setShowReasonDropdown(false)
    setShowSimpleResponse(false)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/feedback-simple`, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response)=>{
      response.text().then((responseString)=>{
        const d = JSON.parse(responseString)
        setShowSimpleResponse(false)
        setSimpleResponseIsLoading(false)
      }).catch((err)=>{
        console.error(err)
        setShowSimpleResponse(false)
        setSimpleResponseIsLoading(false)
      })
    })
    .catch((err)=>{
      setSimpleResponseIsLoading(false)
      console.error(err)
    })
  }
  function onClickSimpleResponse(positive:boolean) {
    if(!positive) {
      setShowReasonDropdown(!showReasonDropdown)
    } else {
      SaveSimpleResponse({
        url: window.location.href,
        story_name: story.name,
        story_uuid: story.id,
        story_slug: story.full_slug,
        helpful: positive
      })
    }
  }

  function handleClickSimpleReason(reason: string) {
    SaveSimpleResponse({
      url: window.location.href,
      story_name: story.name,
      story_uuid: story.uuid,
      story_slug: story.full_slug,
      helpful: false,
      reason: reason,
      enact_user_uid: ""
    })
    setShowReasonDropdown(false)
  }

  return (
    <div>
      <FeedbackPanelForm modalId={MODAL_ID} />
      <PanelWrapper blok={blok}>
        <div className={`container ${styles.feedbackWrapper}`}>
          <div>
            {blok.question_text ? <span className={styles.question}>{blok.question_text}</span> : null}
            <span className={styles.actionButtons}>
              {simpleResponseIsLoading ? (<div className="small-inline-loader" />) : null}
              {showSimpleResponse ? <button onClick={(e)=>{ onClickSimpleResponse(true) }}>Yes</button> : null}
              {showSimpleResponse ? <button onClick={(e)=>{ onClickSimpleResponse(false) }}>No</button> : null}
              {!showSimpleResponse && !simpleResponseIsLoading ? (<span>Your response has been recorded.</span>) : null}
              {showReasonDropdown ? (
                <ul className={styles.reasonDropdown}>
                  <li onClick={(e)=>{handleClickSimpleReason("It wasn't accurate")}}><span />It wasn't accurate</li>
                  <li onClick={(e)=>{handleClickSimpleReason("It wasn't clear")}}><span />It wasn't clear</li>
                  <li onClick={(e)=>{handleClickSimpleReason("It wasn't relevant")}}><span />It wasn't relevant</li>
                </ul>
              ) : null}
            </span>
            <span className={styles.feedback}></span>
            {blok.feedback_text ? (
              <button type="button" data-bs-toggle="modal" data-bs-target={`#${MODAL_ID}`}>{blok.feedback_text}</button>
            ) : null}
          </div>
          {blok.description_text ? <div className={styles.description}>{blok.description_text}</div> : null}
        </div>
      </PanelWrapper>
    </div>
  )
}

export default FeedbackPanel