import React, { useState } from "react"
import StoryblokLink from "../storyblokLink/StoryblokLink"

import AssetCard from "../assetCard/AssetCard"

import { StyleKeyToClassName } from "../../../siteUtils/datasources"
// @ts-ignore
import * as styles from "./AssetListingPanel.module.css"

interface AssetListingProps {
  canMinimize: boolean,
  defaultOpen? : boolean,
  label: string,
  assets: Array<Asset>,
  maxItems?: number,
  seeMoreLink?: SBLink,
  seeMoreLabel?: string,
  seeMoreStyle?: string
}
function AssetListingPanel({ label, assets, canMinimize, defaultOpen, seeMoreLink, seeMoreLabel, seeMoreStyle, maxItems }:AssetListingProps):JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>( canMinimize ? defaultOpen || false : true)

  function toggleAccordian(event:React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  let assetList:Array<Asset> = assets
  if(maxItems && assets.length > maxItems) {
    assetList = assets.slice(0, maxItems)
  }

  return (
    <div>
      {label ? (
        <div 
          className={`${canMinimize ? `accordian-trigger ${isOpen ? "accordian-open" : ""}` : ""} ${styles.header}`} 
          style={{backgroundColor: canMinimize ? "" : "#ababab", color: "#fff"}} 
          onClick={canMinimize ? toggleAccordian : ()=>{}}>
              {canMinimize ? isOpen ? "-" : "+" : ""}&nbsp;{label}
        </div>
      ) : null}
      <br/>
      <div className={`row accordian ${isOpen ? "accordian-open" : ""}`}>
        {assetList.map((asset)=>{
          let showNewTag = false
          if(asset.asset_type === "video" && asset.new_until_date) {
            if(asset.new_until_date.getTime() > Date.now()) {
              showNewTag = true
            }
          }
          return (
            <div className="col-sm-6 col-md-4" key={asset.uuid}>
              <AssetCard
                thumbnail={asset.thumbnail}
                name={asset.name}
                asset_type={asset.asset_type}
                full_slug={asset.full_slug}
                show_new_tag={showNewTag} />
            </div>
          )
        })}
        {maxItems && assets.length > maxItems && seeMoreLabel ? (
          <div style={{textAlign: "center"}}>
            {seeMoreLink ? <StoryblokLink link={seeMoreLink} withClass={StyleKeyToClassName(seeMoreStyle || "")}>{seeMoreLabel}</StoryblokLink> : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AssetListingPanel