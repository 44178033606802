import React from "react"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import AssetListingPanel from "../../internal/assetListingPanel/AssetListingPanel"

import useAssets from "../../../hooks/useAssets"


interface AssetListingByTopicBlok extends SBPanel {
  topic: string,
  label: string,
  max_items?: number,
  can_minimize: boolean,
  default_open: boolean,
  see_more_style: string,
  see_more_label: string,
  see_more_link: SBLink
}
interface AssetListingByTopicProps {
  blok: AssetListingByTopicBlok
}
function AssetListingByTopicPanel({ blok }:AssetListingByTopicProps):JSX.Element {
  const allAssets = useAssets()

  const assetList:Array<Asset> = []
  allAssets.forEach((asset)=>{
    if(asset.topic === blok.topic) {
      assetList.push(asset)
    }
  })

  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <AssetListingPanel 
          canMinimize={blok.can_minimize}
          defaultOpen={blok.default_open}
          label={blok.label}
          assets={assetList}
          maxItems={blok.max_items}
          seeMoreLabel={blok.see_more_label}
          seeMoreLink={blok.see_more_link}
          seeMoreStyle={blok.see_more_style} />
      </div>
    </PanelWrapper>
  )
}

export default AssetListingByTopicPanel