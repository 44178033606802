import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../siteUtils/richText"
import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"

function HorizontalSpacingKeyToGutterWidthBootrap5Class(key:string):string {
  switch (key) {
    case "small":
      return "g-1"
    case "medium":
      return "g-3"
    case "large":
      return "g-5"
    default:
      return "g-0"
  }
}

interface SingleImage {
  component: string,
  _uid: string,
  image: SBImage
}
interface ImageRowComponent extends SBPanel {
  title: Richtext,
  full_width: boolean,
  horizontal_spacing: string,
  image_styling: string,
  images: Array<SingleImage>
}
interface ImageRowProps {
  blok: ImageRowComponent
}
function ImageRowPanel({ blok }:ImageRowProps):JSX.Element {

  return (
    <PanelWrapper blok={blok}>
      <div className={`${blok.full_width ? "" : "container"}`}>
        {blok.title ? <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.title)} /> : null}
        <div className={`row ${HorizontalSpacingKeyToGutterWidthBootrap5Class(blok.horizontal_spacing)} ${blok.image_styling}`}>
          {blok.images.map(image=>(
            <div className="col" key={image._uid}>
              <StoryblokImage image={image.image} />
            </div>
          ))}
        </div>
      </div>
    </PanelWrapper>
  )
}

export default ImageRowPanel