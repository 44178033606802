import React from "react"
// @ts-ignore
import SbEditable from "storyblok-react"

// Panels
import RichTextPanel from "./panels/richTextPanel/RichTextPanel"
import TwoColTextImagePanel from "./panels/twoColTextImagePanel/TwoColTextImagePanel"
import AssetListingByTopicPanel from "./panels/assetListingByTopicPanel/AssetListingByTopicPanel"
import FeedbackPanel from "./panels/feedbackPanel/FeedbackPanel"
import RelatedAssetsPanel from "./panels/relatedAssetsPanel/RelatedAssetsPanel"
import ImageRowPanel from "./panels/imageRowPanel/ImageRowPanel"
import PrimaryAssetPanel from "./panels/primaryAssetPanel/PrimaryAssetPanel"
import IspringEmbedTrainingPanel from "./panels/ispringEmbedTrainingPanel/IspringEmbedTrainingPanel"
import VideoBannerPanel from "./panels/videoBannerPanel/VideoBannerPanel"
import TimelinePanel from "./panels/timelinePanel/TimelinePanel"
import TopicVideoListingPanel from "./panels/assetListingByTopicPanel/AssetListingByTopicPanel"
import CtaTextPanel from "./panels/CtaTextPanel/CtaTextPanel"
import TwoColRichTextPanel from "./panels/twoColRichTextPanel/TwoColRichTextPanel"
import AssetCuratedListingPanel from "./panels/assetCuratedListingPanel/AssetCuratedListingPanel"
import HeroPanel from "./panels/heroPanel/HeroPanel"

// Widgets
import TextWidget from "./widgets/textWidget/TextWidget"
import IqsButton from "./widgets/iqsButton/IqsButton"

// Wrappers
import ContentChangeWrapper from "./wrappers/contentChangeWrapper/ContentChangeWrapper"

const Components:any = {
  // Panels
  "asset_listing_by_topic_panel": AssetListingByTopicPanel,
  "asset_curated_listing_panel":  AssetCuratedListingPanel,
  "cta_text_panel":               CtaTextPanel,
  "feedback_panel":               FeedbackPanel,
  "hero_panel":                   HeroPanel,
  "image_row_panel":              ImageRowPanel,
  "ispring_embed_training_panel": IspringEmbedTrainingPanel,
  "primary_asset_panel":          PrimaryAssetPanel,
  "related_assets_panel":         RelatedAssetsPanel,
  "rich_text_panel":              RichTextPanel,
  "timeline_panel":               TimelinePanel,
  "two_column_image_text_panel":  TwoColTextImagePanel,
  "topic_video_listing_panel":    TopicVideoListingPanel,
  "two_column_rich_text":         TwoColRichTextPanel,
  "video_banner_panel":           VideoBannerPanel,

  // Widgets
  "iqs_button":     IqsButton,
  "text_widget":    TextWidget,

  // Wrappers
  "content_change_wrapper": ContentChangeWrapper,
}

interface DynamicComponentProps {
  blok: any,
  editable?: boolean,
  story?: any
}
const DynamicComponent = ({ blok, editable, story }:DynamicComponentProps):JSX.Element => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]

    if(editable != false) {
      return (<SbEditable content={blok}><Component blok={blok} key={blok._uid} story={story} /></SbEditable>)
    } else {
      return (<Component blok={blok} key={blok._uid} story={story} />)
    }
  }
 
  return  (
    <p>{`Component [${blok.component}] does not exist.`}</p>
  )
}

export function RenderComponents(list:Array<any>, story?:any):Array<JSX.Element> {
  return list.map((blok:any)=>{
    return (<DynamicComponent key={blok._uid} blok={blok} story={story} />)
  })
}

export default DynamicComponent