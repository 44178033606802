import React, {useState} from "react"
import { Modal } from "react-bootstrap"
import StoryblokLink from "../storyblokLink/StoryblokLink"

// @ts-ignore
import * as styles from "./TimelineItem.module.css"


function StatusToClassName(status:string):string {
  switch (status) {
    case "1":
      return styles.timeline_active
    case "2":
      return styles.timeline_complete
    default:
      return ""
  }
}

interface TimelineItemProps {
  status: string,
  title: string,
  shortDescription: string,
  showLeftLine: boolean,
  showRightLine: boolean,
  lineColor: string,
  link?: SBLink,
  ispring_url: string
}
function TimelineItem({ status, title, shortDescription, showLeftLine, showRightLine, lineColor, link, ispring_url }:TimelineItemProps):JSX.Element {
  let color = lineColor || "#c4c4c4"
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.timeline}>
        {showLeftLine ? (<div className={styles.leftLine} style={{backgroundColor: color}} />) : null}
        {showRightLine ? (<div className={styles.rightLine} style={{backgroundColor: color}} />) : null}
        <div className={styles.circleWrapper}>
          <span className={styles.circleLink} onClick={()=>{setShow(true)}}></span>
          {/* <span className={styles.circleLink}><StoryblokLink link={link} /></span> */}
          <div className={`${styles.circle} ${StatusToClassName(status)}`} style={{backgroundColor: color}} />
          {status === "1" || status === "2" ? (<div className={styles.dot} />) : null}
        </div>
      </div>
      <div className={styles.content}>
        {title ? (<div className={styles.title} onClick={()=>{setShow(true)}}>{title}</div>) : null}
        {/* {title ? (<StoryblokLink link={link}><div className={styles.title}>{title}</div></StoryblokLink>) : null} */}
        {shortDescription ? (<div className={styles.description}>{shortDescription}</div>) : null}
      </div>
      <Modal 
        className={"videoModal"}
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <button type="button" className={"btn-close"} aria-label="Close" onClick={() => {setShow(false)}}></button>
        </Modal.Header>
        <Modal.Body>
          <div className={"videoWrapper"}>
          <iframe
            width="920"
            height="518"
            src={ispring_url}
            title="TimeLine Modal"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TimelineItem