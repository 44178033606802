//
// Ispring Embed Training Panel
//
import React from "react"

// @ts-ignore
import * as styles from "./IspringEmbedTrainingPanel.module.css"

interface IspringEmbedTraining {
  embed_code?: string,
  ispring_url?: string
}
interface IspringEmbedTrainingProps {
  blok: IspringEmbedTraining
}
function IspringEmbedTrainingPanel({ blok }:IspringEmbedTrainingProps):JSX.Element {

  if(blok.embed_code) {
    return (
      <div className={styles.videoWrapper} dangerouslySetInnerHTML={{__html: blok.embed_code}} >
      </div>
    )
  } else if(blok.ispring_url) {
    const uuid = blok.ispring_url.substring(blok.ispring_url.lastIndexOf('/'))
    const embedCode = `<iframe src="https://new-acc-space-10528.ispring.com/s/embed_player/${uuid}" width="560" height="315" frameborder="0" border="0" scrolling="auto" allowtransparency="true" allowfullscreen="1" style="border: none; background-color: transparent;"></iframe>`
    return (
      <div className={styles.videoWrapper} dangerouslySetInnerHTML={{__html: embedCode}} >
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default IspringEmbedTrainingPanel