import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../siteUtils/richText"


function SizeKeyToWidthValues(key:string):Array<number> {
  switch(key) {
    case "eight_four":
      return [8,4]
    case "seven_five":
      return [7,5]
    case "six_six":
      return [6,6]
    case "five_seven":
      return [5,7]
    case "four_eight":
      return [4,8]
    default:
      return [6,6]
  }
}


interface TwoColumnRichTextComponent extends SBPanel {
  col_1_content: Richtext,
  col_2_content: Richtext,
  column_sizes: string,
}
interface TwoColumnRichTextProps {
  blok: TwoColumnRichTextComponent
}
function TwoColRichTextPanel({ blok }:TwoColumnRichTextProps):JSX.Element {
  const colSizes = SizeKeyToWidthValues(blok.column_sizes)

  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <div className="row">
          <div className={`col-lg-${colSizes[0]}`} >
            <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.col_1_content)} />
          </div>
          <div className={`col-lg-${colSizes[1]}`}>
            <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(blok.col_2_content)} />
          </div>
        </div>
      </div>
    </PanelWrapper>
  )
}

export default TwoColRichTextPanel